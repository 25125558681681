import axios from "axios";

let curStore = null;
/** @type {"dev" | "prod" | "whitelist"} */
const env = "whitelist";

export const setStore = st => curStore = st;
export const getStore = () => curStore;

export const setCSRF = () => {
  return axios({
    url: `${backendURL}/api/csrf`,
    method: "PUT",
    ...axiosConfig,
  })
    .then((res) => (axios.defaults.headers.common["X-XSRF-TOKEN"] = res.data))
    .catch(() => { });
};

export const parseData = (data) => {
  const dataDecoder = str => {
    try {
      return decodeURI(str);
    } catch {
      return str;
    };
  };
  if (data === null || data === undefined) return data;
  switch (typeof data) {
    case "string": return dataDecoder(data);
    case "number": return Number(data);
    case "object":
      if (data instanceof Array) {
        return data.map(item => parseData(item));
      } else {
        let final = {};
        Object.keys(data).forEach(item => {
          final[item] = parseData(data[item]);
        });
        return final;
      };
    case "boolean": return (String(data) === "true") ? true : false;
    default: return data;
  };
};

export const setAxiosDataHandler = () => {
  axios.interceptors.response.use((res) => {
    if (res.data.status === "ok") {
      res.data.data = parseData(res.data.data);
      return res;
    } else {
      return res;
    };
  }, err => {
    return Promise.reject(err);
  })
};

let cfg = {};
let bUrl = "";

switch (env) {
  case "dev":
    cfg = {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://dev.local:3000",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
      }
    };
    bUrl = "http://api.dev.local:5000";
    break;
  case "prod": 
    cfg = {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "https://scale-proxy.com",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
      }
    };
    bUrl = `https://api.scale-proxy.com:5000`;
    break;
  case "whitelist": 
    cfg = {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "https://api.dashboard.proxypro.tech",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
      }
    };
    bUrl = `https://api.dashboard.proxypro.tech:5000`;
    break;
  default: break;
};

export const backendURL = bUrl;
export const axiosConfig = cfg;
export const genericError = {status: "error", data: "SERVER_ERROR"};